<template>
  <section class="dashboard">
    <h1>
      Admin Dashboard
    </h1>
    <p>Showadz Accounts list</p>
    <p>Total Users: {{ usersList.length }}</p>
    <p>Total Accounts: {{ accountsList.length }}</p>

    <div class="grid">
      <router-link :to="{ name: 'AdminViewAccount', params: { accountId: item.id } }" :class="{ 'is-off': !item.isActive, 'is-cleaned': item.id === 'z3NkVcjvLYSH9NVxvnw9BwYrZkt1' || item.id === 'XUWMQ8gegWVjWLPHm0uC7Z6buld2' || item.id === 'F8ZGKhZrOrRgSpERTAHkxbCiDbx1' || item.id === 'q0D4CVjzaRgWvexazhDx' || item.id === 'p7lv7DFq8LuWNExkFrDO' || item.id === 'jObcqjsaRHUyjqJPOAyjKWCbhFl1' || item.id === 'WYsLxJM8Jd3dXJyRLEXi' || item.id === '4Wo75vIGxneCEvXnlrA6xH75f8u1' || item.id === 'fvoGvCtdRcXXJYAUaKXB39qYJBL2' }" class="account-item" v-for="(item, i) in accountsListSorted" :key="i">
        <img v-if="item.profileImg" :src="item.profileImg">
        <h2>{{ item.businessName }}</h2>
        <h3>{{ item.id }}</h3>
        <p><strong>Owner:</strong> {{ usersList.find(i => i.id === item.id) ? usersList.find(i => i.id === item.id).email : 'User Not Found / Deleted' }}</p>
        <p><strong>Nb Users:</strong> {{ item.usersList ? item.usersList.length : 0 }}</p>
        <!-- <p><strong>Created:</strong> {{ item.createTimestamp }}</p> -->
        <!-- <p><strong>Updated:</strong> {{ item.updateTimestamp }}</p> -->
        <p><strong>Content updated:</strong> {{ item.latestUpdateTimestamp }}</p>
        <!-- <p><strong>Nb Clients:</strong> ???</p> -->
        <!-- <p><strong>Nb Campaigns:</strong> ???</p> -->
        <!-- <p><strong>Nb Adz:</strong> ???</p> -->
      </router-link>
    </div>
  </section>
</template>

<script>
import { isNil } from 'lodash'
import { mapState } from 'vuex'
import UsersDB from '@/firebase/users-db'
import AccountsDB from '@/firebase/accounts-db'
import AccountClientsDB from '@/firebase/account-clients-db'

export default {
  name: 'AdminDashboard',

  head: function () {
    return {
      title: {
        inner: 'Admin Dashboard',
      },
      meta: [
        {
          name: 'description',
          content: `Admin accounts list dashboard`,
          id: 'desc',
        },
      ],
    }
  },

  data() {
    return {
      isLoading: true,
      usersList: [],
      accountsList: [],
      accountsListSorted: [],
    }
  },

  computed: {
    ...mapState('app', ['networkOnLine', 'appTitle']),
    ...mapState('authentication', ['user']),
    ...mapState('users', ['account', 'clients']),
  },

  watch: {
    user: {
      async handler(user) {
        if (!isNil(user)) {
          if (user.appRole !== 'admin') {
            this.$router.push('/')
          }

          const usersDb = new UsersDB()
          this.usersList = await usersDb.readAll()

          const accountsDb = new AccountsDB()
          this.accountsList = await accountsDb.readAll()

          await this.getLatestUpdateTimestamp()

          this.isLoading = false
        }
      },
      immediate: true,
    },
  },

  methods: {
    async getLatestUpdateTimestamp() {
      let progress = 0
      const total = this.accountsList.length

      this.accountsList.map(async (accountObj) => {
        const newAccountObj = accountObj

        const accountClientsDb = new AccountClientsDB(accountObj.id)
        const latest = await accountClientsDb.findFirst()
        const latestTimestamp = latest.length ? latest[0].updateTimestamp : 'Empty'

        newAccountObj.latestUpdateTimestamp = latestTimestamp

        this.accountsList.find(i => i.id === newAccountObj.id).latestUpdateTimestamp = latestTimestamp
        this.accountsList.find(i => i.id === newAccountObj.id).latestUpdateTimestampSort = new Date(latestTimestamp).valueOf()

        progress = progress + 1

        if (progress === total) {
          this.accountsListSorted = this.accountsList.sort((a, b) => {
            if (a.latestUpdateTimestampSort > b.latestUpdateTimestampSort) {
              return -1
            }
            if (a.latestUpdateTimestampSort < b.latestUpdateTimestampSort) {
              return 1
            }
            // a must be equal to b
            return 0
          })
        }
      })
    }
  },
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.dashboard {
  padding: 25px;
}

.grid {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;

  padding: 45px 0;

  .account-item {
    width: calc(98% / 3);
    padding: 20px;
    background: rgba(#000, 0.05123);
    border: 2px solid rgba(#000, 0.05123);
    border-radius: 20px;
    margin-bottom: 1%;
    text-align: center;

    color: #323232;

    &.is-cleaned {
      opacity: 0.25;
    }

    &.is-off {
      display: none;
    }

    img {
      display: block;
      margin: 0 auto;
      width: 100%;
      max-width: 60px;
      border-radius: 50%;
    }

    h2 {
      margin-bottom: 15px;
    }

    p {
      font-size: 12px;
      line-height: 2;
    }
  }
}
</style>
